.project-grid {

	background: #222222;
    grid-template-columns: repeat(1, 1fr);	
	transition: background 500ms ease, width 5s ease; 

    display: -ms-grid;
	display: block;
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    -ms-grid-columns: 2fr 1fr;

    @supports (display: grid) {
	    display: grid;
		
		a {
			border-bottom: none;
		}
    }

    @media screen and ( min-width: 601px ) {
      	grid-template-columns: repeat(2, 1fr);	
    }
	
	@media screen and ( max-device-width: 414px ) {
		background: #ffffff;
	}

}

.project-wrapper {
	display: table;
	padding: 1.25rem;
}