html {
  box-sizing: border-box;
  font-size: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: unset;
}

ol, ul {
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
}