.about {
	
	&--wrapper {
		margin-top: 6.3125rem;
		padding: 0 1.25rem;
		width: 100%;

		p {
			margin-bottom: 2rem;
		}
		
		@media screen and ( min-width: 550px ) {
			margin-top: 7.5rem;
			padding: 0 2.5rem;
			width: 35.25rem;
		}

		li {
			display: inline-block;
			margin-right: 0.5rem;
		}

		li:not(:last-of-type)::after {
			content: " |";
		}

	}

}