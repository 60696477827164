body {
	background: #000000;
	color: #ffffff;
	margin: 0;
}

main {
	margin-top: 4.0625rem;
}

a {
	color: #ffffff;
	text-decoration: none;
}