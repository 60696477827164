html {
	font-size: 14px;
	color: #ffffff;
    font-family:"Unica", serif;
    line-height: 1.2;

	@media screen and ( min-width: 550px ) {
		font-size: 11px;
	}
	
	@media screen and ( min-width: 750px ) {
		font-size: 14px;
	}

	@media screen and ( max-device-width: 769px ) {
		font-size: 13px;
	}

	@media screen and ( min-width: 1024px ) {
		font-size: 16px;
	}

	@media screen and ( min-width: 1441px ) {
		font-size: 18px;
	}

    @media screen and ( min-width: 1681px ) {
    	font-size: 20px;
    }

    @media screen and ( min-width: 2250px ) {
    	font-size: 24px;
    }
}

body {
	font-size: 1.25rem;
}

.is-active,
header a:hover,
.about--wrapper a:hover {
	text-decoration: underline;
}

.project--details h2 {
	line-height: 1.14;
}

.project--details h2 + p {
	line-height: 1.2;
}