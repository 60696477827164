.site-header {
	
	background: #000000;
	height: 4.0625rem;
	border-bottom: 1px solid #222222;
	z-index: 25;

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	li {
		display: inline;
	}

	li:first-of-type {
		margin-right: 1.25rem;
	}

	@media screen and ( max-device-width: 414px ) {
		border-bottom: 1px solid #ffffff;
	}

}

.site-navigation {
	
	&__active {
		border-bottom: 1px solid #ffffff;
	}

}