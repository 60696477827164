.project {

	&--item {
		background: #000000;
		transition: background 500ms ease;

		&:hover {
			background: #ffffff;
			color: #000000;
		}
		
		display: inline-block;
		width: 50%;

		&:nth-of-type(odd) {
			float: left;
		}

		&:nth-of-type(even) {
			float: right;
		}

		border-color: #222222;
		border-style: solid;
		border-width: 0 1px 1px 0;

		@supports (display: grid) {
			
			border-width: 0 0 0 0;
			display: block;
			width: auto;

			&:nth-of-type(odd) {
				float: none;
			}

			&:nth-of-type(even) {
				float: none;
			}

		}

	}

	&--wrap {
		
		position: relative;

		&::before {
			
			display: block;
			content: " ";

			width: 100%;
			padding-top: 56%;

			@media screen and ( min-width: 601px ) {
				padding-top: 46%;
			}

		}

	}

	&--wrap-inner {
	
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 1.25rem 1.25rem;
	
	}

	&--details {
		
		height: 100%;
		position: relative;

		> p {
			position: absolute;
			bottom: 0;
		}

	}
	

}

.project--description {

	&__mooradian-studio {
		width: 26rem;
	}

	&__classic,
	&__aloof,
	&__irenie,
	&__alford-porter,
	&__robert-ames {
		width: 23rem;
	}

	&__david-king,
	&__polytechnic,
	&__not-ltd,
	&__initiate-architecture,
	&__building-ways,
	&__everything-in-between,
	&__esther-breithoff {
		width: 25rem;
	}

	&__dermatology-m,
	&__re-place-london,
	&__tuckey-design-studio {
		width: 22rem;
	}

	&__pit-magazine,
	&__spratley-and-partners,
	&__citizen-magazine,
	&__helen-graves,
	&__blanchard-house,
	&__off-the-wall,
	&__thirzie-hull {
		width: 21rem;
	}

	&__svk-interior-design,
	&__amy-friend-set-design,
	&__alex-hunting-studio,
	&__charlie-hocking {
		width: 20rem;
	}

	&__human3,
	&__pure {
		width: 19rem;
	}

	&__natalino,
	&__leda-athanasopoulou {
		width: 17rem;
	}

}